import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if ((<any>environment).gaTrackId) {
  let gaTrackId = (<any>environment).gaTrackId;
  let customGtagScriptEle: HTMLScriptElement = document.createElement('script');
  customGtagScriptEle.async = true;
  customGtagScriptEle.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackId;
  document.head.prepend(customGtagScriptEle);
  (<any>window).dataLayer = (<any>window).dataLayer || [];
  function gtag(...args: any[]) { (<any>window).dataLayer.push(arguments); };
  gtag('js', new Date());
  gtag('config', gaTrackId);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
