import { Component, OnInit } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './_service';
import { Constants, LocaleService } from './_utility';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'FUtuRI';
  // Sets initial value to true to show loading spinner on first load
  loading = true;

  constructor(private router: Router, private locale: LocaleService, private authService: AuthService,) {
    this.locale.init();
    // Router event per loading page
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  ngOnInit() {
    this.authService.user = null;
    if (this.authService.remember && this.authService.checkEnvironmentCookie) {
      this.authService.getCustomTokenFromCookie().subscribe({
        next: async (customToken) => {
          await this.authService.signInWithCustomToken(customToken);
          let isTeacher = this.authService.user.isTeacher;
          let routerLink = isTeacher ? Constants.Routing.DOCENTE.routerLink : Constants.Routing.STUDENTE.routerLink;
          this.router.navigate(routerLink);
        }
      });
    } else {
      this.authService.logout();
    }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => this.loading = false, 1000);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => this.loading = false, 1000);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => this.loading = false, 1000);
    }
  }
}
